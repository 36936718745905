import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useEffect, useRef, useState } from "react"

import Link from "gatsby-link";


import * as styles from "../scss/privacy.module.scss"


const Imprint = () => {
  const shapeFirst = useRef(null)
  const shapeSecond = useRef(null)
  const shapeThird = useRef(null)

  useEffect(() => {
    
    const shapeFistOffsetTop = shapeFirst.current.offsetTop
    
    const shapeSecondOffsetTop = shapeSecond.current.offsetTop
    
    window.addEventListener("scroll", () => {
      if (shapeFirst.current) {
        shapeFirst.current.style.top =
          shapeFistOffsetTop + window.scrollY / 3 + "px"
        shapeSecond.current.style.top =
          shapeSecondOffsetTop - window.scrollY / 3 + "px"
      } else {
        window.removeEventListener("scroll", () => {})
      }
    })
  })


return(
  <Layout>
    <SEO
        title="Impressum"
        description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
        keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
        author= "spectory"
    />
    <div className={styles.responsiveWrapper}>
    <div className={styles.hero}>
      <div  className={`clearfix container ${styles.container}`} style ={{margin:0,padding:0}}>
        <div className={styles.intro}>
          <h1>
           Impressum
          </h1>
          <div>
            <br/>
            Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
            <br/>
            <br/>
            Johannes Koch-Geiger
            <br/>
            Johannes Koch
            <br/>
            Klosterstraße 3,
            <br/>
            4020 Linz,
            <br/>
            Österreich
            <br/>
            <br/>
            Unternehmensgegenstand: Humanenergetik
            <br/>
            <br/>
            Tel.: 0650/7598580
            E-Mail: info@energiepraxis.com
            <br/>
            <br/>
            Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
            <br/>
            <br/>
            Aufsichtsbehörde/Gewerbebehörde: Magistrat Linz
            Verleihungsstaat: Österreich
            <br/>
            <br/>
            Quelle: Erstellt mit dem Impressum Generator von firmenwebseiten.at in Kooperation mit ilovevienna.at
            <br/>
            <br/>
            EU-Streitschlichtung
            <br/>
            Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
            Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=221096992 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
            <br/>
            <br/>
            Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            <br/>
            <br/>
            Haftung für Inhalte dieser Webseite
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
            <br/>
            <br/>
            Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
^           <br/>
            <br/>
            Haftung für Links auf dieser Webseite <br/>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
            <br/>
            <br/>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
            <br/>
            <br/>
            Urheberrechtshinweis 
            <br/>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
            <br/>
            <br/>
            Bildernachweis
            <br/>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
            <br/>
            <br/>
            Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
            spectory OG
            <br/>
            <br/>
            Quelle: Erstellt mit dem Datenschutz Generator von firmenwebseiten.at in Kooperation mit gutetipps.at
          </div>  
        </div>
        <div ref={shapeFirst} className={styles.shapeFirst}></div>
        <div ref={shapeSecond} className={styles.shapeSecond}></div>
        <div ref={shapeThird} className={styles.shapeThird}></div>
      </div>
    </div>
    
    <div  style ={{height:40, backgroundColor: "#E4F8EF" , width:"100%",position:"absolute",bottom:0,left:0,display:"block"}}></div>
    </div>
  </Layout>
  )
}

export default Imprint